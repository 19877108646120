import { useCallback, useState, memo, ReactElement, ChangeEvent } from 'react';
import { trackSubscribed, userUpdate } from '@/common/app/utils/iterable';
import { getLS, setLS, LocalStorageTypes } from '@/common/service/storage';
import { AnalyticsInstance } from '@/common/service/analytics';
import * as S from './Subscribe.styles';
import { Warning } from '@/shared/images/icons/Warning';
import { useThemeContext } from '@/common/app/contexts/ThemeContext';
import { ErrorMessage } from '@/screens/ActivityPage/ui/Calendar/ErrorMessage/ErrorMessage';
import { Container } from '@/common/ui/containers/Container/Container';
import { isValidEmail } from '@/common/app/utils/typeUtils';

const Subscribe = (): ReactElement => {
    const [isAlready, setIsAlready] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmited, setIsSubmited] = useState(false);

    const { theme } = useThemeContext();

    const isDark = theme.name === 'dark';

    const onChangeEmail = useCallback(
        (evt: string) => {
            setEmail(evt || '');
            if (isSubmited) {
                setIsError(!isValidEmail(evt));
            }
        },
        [isSubmited, setEmail]
    );

    const onSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setIsSubmited(true);
        if (!/^.+@.+\..+$/g.test(email)) {
            setIsError(true);
            setErrorMessage('Please enter valid e-mail address');
        } else {
            const contactInfo = getLS(LocalStorageTypes.LS_CONTACT_INFO) || {};

            userUpdate(email, {
                firstName: contactInfo.firstName || '',
                lastName: contactInfo.lastName || '',
                fullName: [contactInfo.firstName, contactInfo.lastName].filter(Boolean).join(' '),
                phoneNumber: contactInfo.phone || '',
            });
            setIsSubmited(false);
            trackSubscribed({ email }).then((res) => {
                if (res.status === 200) {
                    setLS(LocalStorageTypes.LS_CONTACT_INFO, { ...contactInfo, email });

                    setIsAlready(true);
                } else if (res.status === 400) {
                    setIsError(true);
                    setErrorMessage(`Invalid email: ${email}`);
                } else {
                    setIsError(true);
                    setErrorMessage('Something is wrong');
                }
            });

            AnalyticsInstance.account.subscribe({ email: email });

            AnalyticsInstance.subscribe();
        }
    };

    return (
        <S.Wrapper data-test-id="Subscribe">
            <Container>
                {isAlready ? (
                    <S.ContainerThankYou id="subscribed">
                        <S.LeftPart>
                            <S.Title>Thank you!</S.Title>
                            <S.Description isAlready={isAlready}>
                                Something great is headed your way
                            </S.Description>
                        </S.LeftPart>
                        <S.ThankYou isDark={isDark} />
                    </S.ContainerThankYou>
                ) : (
                    <S.Container id="subscribe_widget">
                        <div>
                            <S.Title>Be in the know!</S.Title>
                            <S.Description isAlready={isAlready}>
                                Be informed about our latest offers
                            </S.Description>
                        </div>
                        <S.InputWrap>
                            <S.Input
                                placeholder="Email Address"
                                value={email}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    onChangeEmail(e.target.value)
                                }
                            />
                            <S.Button onClick={(evt) => onSubmit(evt)}>
                                <S.ArrowIcon />
                            </S.Button>
                            {!isError && (
                                <S.Message>
                                    <Warning /> You can unsubscribe at any time
                                </S.Message>
                            )}
                            {isError && <ErrorMessage message={errorMessage} />}
                        </S.InputWrap>
                    </S.Container>
                )}
            </Container>
        </S.Wrapper>
    );
};

export default memo(Subscribe);
