import { memo, ReactElement } from 'react';
import * as S from './RecentlySearchedDestination.styles';
import { Button } from '@/common/ui';
import { Container } from '@/common/ui/containers/Container/Container';
import router from 'next/router';
import { IAttractionsSearch } from '@/common/app/contexts/AttractionsContext';
import { useSearchContext } from '@/common/app/contexts';
import { attractionsUrl } from '@/common/app/utils/urlsUtils';
import { dateQueryString } from '@/common/app/utils/dateUtils';

type Category = {
    id: string;
    count: string;
};

export const RecentlySearchedDestination = () => {
    const { recents } = useSearchContext();

    if (!recents || !recents.length) {
        return null;
    }

    const recentDestinationData = recents?.filter(
        (item: IAttractionsSearch) => !!item.destination?.total_activities
    )[0];

    if (!recentDestinationData) {
        return null;
    }

    const {
        destination: recentDestination,
        category: recentDestinationCategory,
        dateRange,
    } = recentDestinationData;

    const { categories, url, total_activities, name, state, slug } = recentDestination;
    const destinationCategories = (categories || '').split(',').map((item: string) => {
        const category = item.split(':');
        return {
            id: category[0],
            count: category[1],
        };
    });

    const currentCategory = destinationCategories.find(
        (item: Category) => item.id === recentDestinationCategory?.id
    );

    const currentCount = currentCategory ? currentCategory.count : total_activities;

    const attractionsCount = () => {
        if (currentCount < 5) return '';
        if (currentCount >= 5 && currentCount < 10) return 5;
        if (currentCount > 10 && currentCount < 20) return 10;
        return 20;
    };

    const title = (): ReactElement => {
        const count = attractionsCount();
        if (currentCategory) {
            return (
                <>
                    Top {count} {name} {recentDestinationCategory?.name}{' '}
                </>
            );
        }
        return (
            <>
                Top {count} Attractions, Tours & Things To Do in {name}, {state.code}{' '}
            </>
        );
    };

    const destinationUrl: string =
        url ||
        attractionsUrl({
            destination: slug,
            category: recentDestinationCategory?.slug,
            from: dateQueryString(new Date(dateRange.dateStart)),
            to: dateQueryString(new Date(dateRange.dateEnd)),
        });

    return (
        <S.Wrapper>
            <Container>
                <S.Container data-test-id="Recently_searched_destination">
                    <S.TitleBtnWrapper>
                        <S.Title>{title()}</S.Title>
                        <Button variant="primary" onClick={() => router.push(destinationUrl)}>
                            Browse Activities
                        </Button>
                    </S.TitleBtnWrapper>
                    <S.ImgBtnWrapper>
                        <S.ImageContainer />
                        <Button variant="primary" onClick={() => router.push(destinationUrl)}>
                            Browse Activities
                        </Button>
                    </S.ImgBtnWrapper>
                </S.Container>
            </Container>
        </S.Wrapper>
    );
};

export default memo(RecentlySearchedDestination);
