import { TDestinationItem } from '@/common/service/api/Destination/Destination.domain';
import { TPublicStory } from '@/common/service/api/Stories/Stories.domain';
import { TPublicBlog } from '@/entities/Blogs/domain/Blogs.domain';

export type TPropsHomePage = {
    blogs: TPublicBlog[];
    stories: TPublicStory[];
    popularDestinations: TDestinationItem[];
};

export enum MetaValues {
    title = `TripShock! - Book The Best Activities, Tours & Things To Do`,
    description = `The USA's #1 booking website for activities, tours, and things to do. Browse reviews, deals, photos and more from over 1,000+ experiences. Low price guarantee on every booking.`,
    imageDescription = `TripShock! - Book The Best Activities, Tours & Things To Do`,
    href = `https://www.tripshock.com/`,
}
