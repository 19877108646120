import { TPropsHomePage } from '@/screens/HomePage/domain/HomePage.domain';
import { GetStaticProps } from 'next';
import {
    getBlogsList,
    getStoriesList,
    getPopularDestinations,
} from '../src/screens/HomePage/service/ApiHomePage';
import { HomePage } from '../src/screens/HomePage/ui/HomePage';

export const getStaticProps: GetStaticProps<TPropsHomePage> = async () => {
    const [blogs, stories, popularDestinations] = await Promise.all([
        getBlogsList(4),
        getStoriesList(4),
        getPopularDestinations(),
    ]);

    const newPopularDestinations = () => {
        const topDestinationsIds = ['12', '59', '41', '60'];
        const topDestinations = popularDestinations.filter(
            (item) => topDestinationsIds.includes(item.id) && item.experiences !== '0 experience'
        );

        const otherDestinations = popularDestinations
            .filter(
                (item) =>
                    !topDestinationsIds.includes(item.id) && item.experiences !== '0 experience'
            )
            .sort((a, b) => +b.experiences.split(' ')[0] - +a.experiences.split(' ')[0]);
        return [...topDestinations, ...otherDestinations];
    };

    return {
        props: {
            blogs,
            stories,
            popularDestinations: newPopularDestinations(),
        },
    };
};

export default HomePage;
