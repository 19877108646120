import { ImageCardProps } from '@/common/domain/UI.domain';
import Link from 'next/link';
import { ReactElement, memo } from 'react';
import * as S from './ImageCard.styles';

export const ImageCard = ({
    imageSrc,
    description,
    imageAlt,
    cardLink,
}: ImageCardProps): ReactElement => {
    return (
        <Link href={cardLink} passHref>
            <S.Wrapper>
                <S.Gradient />
                <S.Image
                    src={imageSrc}
                    alt={imageAlt || description}
                    loading="lazy"
                    width="520"
                    height="300"
                />
                {description && <S.Description>{description}</S.Description>}
            </S.Wrapper>
        </Link>
    );
};

export default memo(ImageCard);
