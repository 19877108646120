import styled, { css } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { radius } from 'src/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { cssVars } from '@/styles/Themes/cssVars';
import { Transition } from '@/styles/Themes/KeyFrames';

export const Wrapper = styled.a`
    position: relative;
    display: flex;
    border-radius: ${radius.small};
    overflow: hidden;
    height: 280px;
    ${Screen.ipad} {
        height: 350px;
    }
    ${Screen.desktop} {
        height: 300px;
    }

    &:hover {
        box-shadow: ${cssVars.shadow_card_hover};
        ${Transition.normal};
    }
`;

export const Gradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
        180deg,
        rgba(12, 11, 11, 0) 31.6%,
        rgba(0, 0, 0, 0.416) 73.45%
    );
`;

export const Image = styled.img`
    width: 100%;
    object-fit: cover;
    height: 100%;
`;

export const Description = styled.span`
    color: ${Palette.white.normal};
    width: 60%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin: 0 0 18px 24px;
    ${css`
        ${Text.Mobile.medium.semibold}
        ${Screen.ipad} {
            ${Text.Ipad.medium.semibold}
            width: 50%;
        }
        ${Screen.desktop} {
            ${Text.Desktop.large.semibold}
            width: 60%;
        }
    `}
`;
