import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import binoculars from '@/shared/images/Images/binoculars.png';

export const Wrapper = styled.div`
    padding: 0 0 25px;

    ${Screen.ipad} {
        padding: 0 0 30px;
    }
    ${Screen.desktop} {
        padding: 30px 0 30px;
    }
`;

export const Container = styled.div`
    background-color: ${cssVars.bg_sand_dark3};
    padding: 32px 20px;
    border-radius: ${radius.small};

    ${Screen.ipad} {
        padding: 36px 30px;
    }
    ${Screen.desktop} {
        display: flex;
        justify-content: space-between;
        padding: 36px 52px;
        margin: 0 auto;
    }
`;

export const TitleBtnWrapper = styled.div`
    > button {
        display: none;
    }

    ${Screen.desktop} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        > button {
            display: block;
            width: 250px;
            margin-bottom: 24px;
        }
    }
`;

export const Title = styled.h2`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.heading3}
    margin: 0 0 16px;

    ${Screen.ipad} {
        margin: 0 0 46px;
    }
    ${Screen.desktop} {
        width: 75%;
    }
`;

export const ImgBtnWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${Screen.desktop} {
        width: 60%;

        > button {
            display: none;
        }
    }
`;

export const ImageContainer = styled.div`
    background-image: url(${binoculars.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 200px;
    margin: -16px 0;

    ${Screen.ipad} {
        width: 100%;
        height: 340px;
        margin: -44px 0 -22px;
    }
    ${Screen.desktop} {
        display: flex;
        align-self: end;
        width: 540px;
        height: 344px;
        margin: -32px 0;
    }
`;

export const noWrapBlock = styled.span`
    white-space: nowrap;
`;
